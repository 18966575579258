<template>
  <Dialog>
    <SuspenseSlotWrapper>
      <Suspense>
        <DialogContent />
        <template #fallback>
          <div style="height: 500px" class="relative p-0 m-0 z-[509]">
            <LoaderPageContent />
          </div>
        </template>
      </Suspense>
    </SuspenseSlotWrapper>
  </Dialog>
</template>
<script setup lang="ts">
import Dialog from '@/components/dialog/components/dialogFrame/fullSize/fullSizeDialogFrame.vue';
import SuspenseSlotWrapper from '~~/src/components/suspenseSlotWrapper.vue';
import LoaderPageContent from '~~/src/components/loader/pageContent/minilu/page-content.vue';
import DialogContent from './components/dialogContent.vue';
import { useDialogStore } from '~/stores/useDialogStore';

const dialogStore = useDialogStore();

dialogStore.setHeadline('shop.actions.product.headline');
</script>
