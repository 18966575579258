<template>
  <div class="sm:px-sm sm:pb-sm md:px-md md:pb-md">
    <template v-if="!error">
      <div v-for="(campaign, index) in campaigns" :key="index">
        <CampaignConfigurator
          :campaign="campaign"
          :product="data?.lineItem?.product"
          is-in-action-page-dialog
          @add-to-cart="
            (items, quantity) => {
              dialog.close(campaign.id, items, quantity);
            }
          "
        />
      </div>
    </template>
    <template v-else>
      <LoadingError />
    </template>
  </div>
</template>
<script setup lang="ts">
import CampaignConfigurator from '@/components/page/product/actions/components/campaignConfigurator/campaignConfigurator.vue';
import { useOrderedCampaigns } from '~/components/page/product/composables/useOrderedCampaigns';
import { useCachedGet } from '~/composables/dataFetching/genericFetchers';
import useActionPageDialog from '~/composables/dialogs/useActionPageDialog';
import type { Campaign } from '~/server/transformers/shop/campaignTransformer';
import { LoadingError } from '@/complib';
import { handleLoadingError } from '~/utils/handleLoadingError';

const dialog = useActionPageDialog();

const data = dialog.getData();
const site = useSiteIdent();
const error = ref(false);

let campaigns: Campaign[] = [];
if (data.lineItem) {
  try {
    const campaignsResponse = await useCachedGet<Campaign[]>(
      `/api/${site}/shop/product/actions/${data.lineItem.productId}`,
    );

    const { orderedCampaigns } = useOrderedCampaigns(
      campaignsResponse.data.value,
    );
    campaigns = orderedCampaigns.value;
  } catch (e) {
    error.value = true;
    handleLoadingError(e);
  }
}
</script>
